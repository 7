import { FunctionComponent, useEffect } from 'react'

type Props = {
  userToken: string
}
export const AppMenuMountDiv: FunctionComponent<Props> = ({ userToken }: Props) => {
  useEffect(() => {
    ;(window as any).tomraMenu.mount(userToken)
  }, [userToken])

  return <div id="menu-root" />
}
