export function getLocale(): string {
  // $FlowFixMe
  return (
    (navigator.languages && navigator.languages[0]) || navigator.language || (navigator as any).userLanguage || 'en'
  )
}

// Doing split() on language since KC only expects "en" as opposed to "en-GB" for instance
export function getLocaleForKeycloak(): string {
  return getLocale().split('-')[0]
}

export function getTimezone(): string {
  return (window as any).Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Berlin'
}
